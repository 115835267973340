const CONFERENCE_DATA = [
  {
    id: '0',
    title: 'Watch the TED2025 talks in real time',
    presenterDisplayName: '',
    primaryImageSet: [
      {
        url: 'https://images.prismic.io/tedconf/Z-8W-HdAxsiBwTXJ_TEDLivehomepage-3-.png?auto=format,compress',
        aspectRatioName: '16x9'
      },
      {
        url: 'https://images.prismic.io/tedconf/Z-8W-XdAxsiBwTXK_TEDLivehomepage3.png?auto=format,compress',
        aspectRatioName: '4x3'
      },
      {
        url: 'https://images.prismic.io/tedconf/Z-7iXndAxsiBwTBE_conference.png?auto=format,compress',
        aspectRatioName: '2x1'
      }
    ],
    topics: {
      nodes: [
        {
          name: 'conference'
        }
      ]
    },
    description:
      'TED2025 is streaming live from Vancouver, Canada! Be one of the first to watch the unedited talks from the conference — including some stage moments that may not go online. Join the audience from anywhere and enjoy permanent access to the on-demand archive with TED Live.',
    duration: null,
    slug: 'https://tedlive.ted.com/webcasts/ted2025/purchase',
    canonicalUrl: 'https://conferences.ted.com/ted2025',
    publishedAt: '',
    type: {
      name: 'Original Content'
    },
    viewedCount: 0
  }
];

export default CONFERENCE_DATA;
